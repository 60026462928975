import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTooltip.figmaUrl.android} codeUrl={checklists.componentTooltip.codeUrl.android} checklists={checklists.componentTooltip.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`A tooltip is a short descriptive message that appears near a view when the user long presses on the view or hovers over it. This tooltip is useful if your app uses icons to represent an action or information to save space in the layout.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/android-compose-tooltip.gif",
            "alt": "Legion Tooltip Android Compose"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Orientations and Animations`}</h2>
    <p>{`We have four orientations :`}</p>
    <ol>
      <li parentName="ol">{`Tooltip Orientation Start`}</li>
      <li parentName="ol">{`Tooltip Orientation Top`}</li>
      <li parentName="ol">{`Tooltip Orientation Bottom`}</li>
      <li parentName="ol">{`Tooltip Orientation End`}</li>
    </ol>
    <p>{`and also have four animations in tooltip arrow :`}</p>
    <ol>
      <li parentName="ol">{`Tooltip Animation Heartbeat`}</li>
      <li parentName="ol">{`Tooltip Animation Rotate`}</li>
      <li parentName="ol">{`Tooltip Animation Shake`}</li>
      <li parentName="ol">{`Tooltip Animation Breath`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`To use the Tooltip component, you need to define the parameter in builder and create instance. After that call function show to display Tooltip in the screen.`}</p>
    <div className="divi" />
    <h2>{`Orientation`}</h2>
    <h3>{`Start`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnTooltip(
    requesterView = {
            Icon(
                imageVector = Icons.Default.Check,
                contentDescription = "",
                modifier = it
            )
    },
    tooltipContent = {
        Text(text = "Now you", color = Color.White, style = LgnTypographyTokens.CaptionLargeRegular)
    },
    backgroundColor = LgnPaletteTokens.Tertiary900,
    arrowOrientation = LgnTooltipOrientation.START
)

`}</code></pre>
    <h3>{`Top`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnTooltip(
    requesterView = {
            Icon(
                imageVector = Icons.Default.Check,
                contentDescription = "",
                modifier = it
            )
    },
    tooltipContent = {
        Text(text = "Now you", color = Color.White, style = LgnTypographyTokens.CaptionLargeRegular)
    },
    backgroundColor = LgnPaletteTokens.Tertiary900,
    arrowOrientation = LgnTooltipOrientation.TOP
)

`}</code></pre>
    <h3>{`Bottom`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnTooltip(
    requesterView = {
            Icon(
                imageVector = Icons.Default.Check,
                contentDescription = "",
                modifier = it
            )
    },
    tooltipContent = {
        Text(text = "Now you", color = Color.White, style = LgnTypographyTokens.CaptionLargeRegular)
    },
    backgroundColor = LgnPaletteTokens.Tertiary900,
    arrowOrientation = LgnTooltipOrientation.BOTTOM
)

`}</code></pre>
    <h3>{`End`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnTooltip(
    requesterView = {
            Icon(
                imageVector = Icons.Default.Check,
                contentDescription = "",
                modifier = it
            )
    },
    tooltipContent = {
        Text(text = "Now you", color = Color.White, style = LgnTypographyTokens.CaptionLargeRegular)
    },
    backgroundColor = LgnPaletteTokens.Tertiary900,
    arrowOrientation = LgnTooltipOrientation.END
)

`}</code></pre>
    <h2>{`Animation`}</h2>
    <h3>{`Heartbeat`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnTooltip(
    requesterView = {
            Icon(
                imageVector = Icons.Default.Check,
                contentDescription = "",
                modifier = it
            )
    },
    tooltipContent = {
        Text(text = "Now you", color = Color.White, style = LgnTypographyTokens.CaptionLargeRegular)
    },
    backgroundColor = LgnPaletteTokens.Tertiary900,
    animation = LgnTooltipAnimation.HEARTBEAT,
    arrowOrientation = LgnTooltipOrientation.TOP
)

`}</code></pre>
    <h3>{`Rotate`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnTooltip(
    requesterView = {
            Icon(
                imageVector = Icons.Default.Check,
                contentDescription = "",
                modifier = it
            )
    },
    tooltipContent = {
        Text(text = "Now you", color = Color.White, style = LgnTypographyTokens.CaptionLargeRegular)
    },
    backgroundColor = LgnPaletteTokens.Tertiary900,
    animation = LgnTooltipAnimation.ROTATE,
    arrowOrientation = LgnTooltipOrientation.TOP
)

`}</code></pre>
    <h3>{`Shake`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnTooltip(
    requesterView = {
            Icon(
                imageVector = Icons.Default.Check,
                contentDescription = "",
                modifier = it
            )
    },
    tooltipContent = {
        Text(text = "Now you", color = Color.White, style = LgnTypographyTokens.CaptionLargeRegular)
    },
    backgroundColor = LgnPaletteTokens.Tertiary900,
    animation = LgnTooltipAnimation.SHAKE,
    arrowOrientation = LgnTooltipOrientation.TOP
)

`}</code></pre>
    <h3>{`Breath`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnTooltip(
    requesterView = {
            Icon(
                imageVector = Icons.Default.Check,
                contentDescription = "",
                modifier = it
            )
    },
    tooltipContent = {
        Text(text = "Now you", color = Color.White, style = LgnTypographyTokens.CaptionLargeRegular)
    },
    backgroundColor = LgnPaletteTokens.Tertiary900,
    animation = LgnTooltipAnimation.BREATH,
    arrowOrientation = LgnTooltipOrientation.TOP
)

`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Descriptions`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`requesterView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@Composable (Modifier) -> Unit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Content For Request Tooltip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tooltipContent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@Composable () -> Unit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Tooltip Content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`animation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnTooltipAnimation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Type of Tooltip Animation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`arrowOrientation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnTooltipOrientation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Tooltip Arrow Orientation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Tooltip Background Color`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      